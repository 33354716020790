import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import { Draggable, Droppable } from '@atom/components/common/dragAndDrop';
import SchemaDetailContext, {
  DragDropType,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import {
  ATTRIBUTE_CREATE,
  ATTRIBUTE_GROUP_DELETE,
  ATTRIBUTE_GROUP_UPDATE,
} from '@atom/graph/schema';
import { Icon, IconButton, List, Tooltip } from '@atom/mui';
import {
  AttributeCreateInput,
  AttributeDataType,
  AttributeGroupDeleteInput,
  AttributeGroupUpdateInput,
  SchemaTree,
  SchemaTreeAttribute,
  SchemaTreeAttributeGroup,
} from '@atom/types/schema';

import AddItemModal from '../AddItemModal';
import EditItemModal from '../EditItemModal';

import AttributeRow from './AttributeRow';

import './subItemDetail.css';

const DEFAULT_ATTRIBUTE = {
  dataType: AttributeDataType.SHORT_TEXT,
  isFilterable: false,
  isVisibleAsSubtext: false,
  isEditable: true,
};

interface Props {
  attributeGroup: SchemaTreeAttributeGroup;
}

const AttributeGroupRow = ({ attributeGroup }: Props) => {
  const container = useRef<HTMLDivElement>();

  const {
    schemaTree,
    refetchSchemaTree,
    selectedSubItem,
    setSelectedAttributeRoute,
  } = useContext(SchemaDetailContext);

  const [overflowing, setOverflowing] = useState<boolean>(false);
  const [openAttributeCreate, setOpenAttributeCreate] = useState<boolean>(
    false,
  );
  const [openAttributeGroupEdit, setOpenAttributeGroupEdit] = useState<boolean>(
    false,
  );
  const [openAttributeGroupDelete, setOpenAttributeGroupDelete] = useState<
    boolean
  >(false);

  const [createAttribute] = useMutation<
    { attributeCreate: SchemaTree },
    { input: AttributeCreateInput }
  >(ATTRIBUTE_CREATE);

  const [updateAttributeGroup] = useMutation<
    { attributeGroupUpdate: SchemaTree },
    { input: AttributeGroupUpdateInput }
  >(ATTRIBUTE_GROUP_UPDATE);

  const [deleteAttributeGroup] = useMutation<
    { attributeGroupDelete: boolean },
    { input: AttributeGroupDeleteInput }
  >(ATTRIBUTE_GROUP_DELETE);

  useEffect(() => {
    const { offsetWidth, scrollWidth } = container.current;
    setOverflowing(offsetWidth < scrollWidth);
  }, [attributeGroup.name]);

  const handleAttributeCreate = async (name: string) => {
    const { data } = await createAttribute({
      variables: {
        input: {
          ...DEFAULT_ATTRIBUTE,
          schemaId: selectedSubItem?.id,
          attributeGroupId: attributeGroup?.id,
          name,
        },
      },
    });

    await refetchSchemaTree();

    setSelectedAttributeRoute({
      attributeGroupId: attributeGroup?.id,
      attributeId: data?.attributeCreate?.id,
    });

    setOpenAttributeCreate(false);
  };

  const handleAttributeGroupEdit = async (name: string) => {
    await updateAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId: attributeGroup?.id,
          name,
        },
      },
    });

    await refetchSchemaTree();

    setOpenAttributeGroupEdit(false);
  };

  const handleAttributeGroupDelete = async () => {
    await deleteAttributeGroup({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId: attributeGroup?.id,
        },
      },
    });

    await refetchSchemaTree();

    setSelectedAttributeRoute(null);
    setOpenAttributeGroupDelete(false);
  };

  const isDisabled = schemaTree?.isPublished;
  const showEditAttribute = !isDisabled;
  const showDeleteAttribute =
    !schemaTree?.isPublished && selectedSubItem.attributeGroups.length > 1;

  return (
    <>
      <div styleName="attribute-group-row">
        <div styleName="attribute-group-title" ref={container}>
          {!overflowing ? (
            attributeGroup.name
          ) : (
            <Tooltip title={attributeGroup.name} placement="bottom-start">
              <p styleName="attribute-group-title">{attributeGroup.name}</p>
            </Tooltip>
          )}
        </div>
        <div>
          <IconButton
            onClick={() => setOpenAttributeCreate(true)}
            size="small"
            tooltip="Add Attribute"
          >
            <Icon>control_point</Icon>
          </IconButton>
          {showEditAttribute && (
            <IconButton
              onClick={() => setOpenAttributeGroupEdit(true)}
              size="small"
              tooltip="Rename"
            >
              <Icon>edit</Icon>
            </IconButton>
          )}
          {showDeleteAttribute && (
            <IconButton
              onClick={() => setOpenAttributeGroupDelete(true)}
              size="small"
              tooltip="Delete"
            >
              <Icon>delete</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <Droppable
        droppableId={attributeGroup.id}
        type={DragDropType.ATTRIBUTE}
        isDropDisabled={isDisabled}
      >
        <List style={{ padding: 0 }}>
          {attributeGroup.attributes.map(
            (attribute: SchemaTreeAttribute, index: number) => {
              return (
                <Draggable
                  key={attribute.id}
                  draggableId={attribute.id}
                  index={index}
                  type={DragDropType.ATTRIBUTE}
                  isDragDisabled={isDisabled}
                >
                  <AttributeRow
                    key={attribute?.id}
                    attribute={attribute}
                    attributeGroupId={attributeGroup.id}
                  />
                </Draggable>
              );
            },
          )}
        </List>
      </Droppable>
      <AddItemModal
        open={openAttributeCreate}
        closeModal={() => setOpenAttributeCreate(false)}
        type="Attribute"
        handleSave={handleAttributeCreate}
      />
      <EditItemModal
        name={attributeGroup.name}
        type="Group"
        handleSave={handleAttributeGroupEdit}
        open={openAttributeGroupEdit}
        closeModal={() => setOpenAttributeGroupEdit(false)}
      />
      <DeleteModal
        open={openAttributeGroupDelete}
        onConfirm={handleAttributeGroupDelete}
        onCancel={() => setOpenAttributeGroupDelete(false)}
        title="Delete Group?"
        content={`Deleting ${attributeGroup.name} will also delete all the items under that group.`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default AttributeGroupRow;
