import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ClickAwayListener } from '@mui/material';

import { Icon, List, Popper, Progress, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import layout from '@atom/styles/layout';
import { BudgetCategory } from '@atom/types/budget';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

const { ListItemButton } = List;

interface Option {
  id: string;
  name: string;
}

export type ComboSelectProps = {
  /**
   * Displayed before selected items and as placeholder text
   */
  itemName: string;
  /**
   * Is the options visible
   */
  open?: boolean;
  /**
   * Set popover open state
   */
  setOpen: (open: boolean) => void;
  /**
   * Objects to display as options
   */
  options: Option[];
  /**
   * Are options loading
   */
  loading: boolean;
  /**
   * Total options for pagination
   */
  total: number;
  /**
   * Highest current page of options
   */
  page: number;
  /**
   * If passed, handles scrolling to bottom of results
   */
  handlePageScroll?: (nextPage: number) => void;
  /**
   * Trigger option call
   */
  fetchOptions: () => void;
  /**
   * Search string from input
   */
  query: string;
  /**
   * Triggers search query
   */
  onQueryChange: (value: string) => void;
  /**
   * Selected option ids
   */
  selectedOptions: BudgetCategory[];
  /**
   * Selected option ids
   */
  onSelectOption: (option: { id: string; name: string }) => void;
  /**
   * Use to clear selected
   */
  clearSelections: () => void;
  /**
   * How many characters to display on the selected string
   * - defaults to 35
   */
  truncation?: number;
  /**
   * Used as id for QA tests
   */
  dataCyLabel: string;
};

const POPPER_HEIGHT = '400px';

const styles = {
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: '0.5rem 0.5rem 0 0.5rem',
    borderBottom: `1px solid ${colors.neutral.gray}`,
    color: colors.neutral.gray,
  },
  noResults: {
    padding: '1rem',
  },
  progressContainer: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  listItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  popper: {
    minWidth: '10rem',
    maxWidth: '23rem',
    backgroundColor: colors.neutral.white,
    boxShadow: layout.muiDropdownBoxShadow,
    maxHeight: POPPER_HEIGHT,
    zIndex: 10,
  },
  pinnedItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pinnedItemName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optionsContainer: {
    maxHeight: '340px',
    overflow: 'auto',
  },
  listDivider: {
    height: '1px',
    borderTop: `1px solid ${colors.neutral.silver}`,
  },
  closeIcon: { cursor: 'pointer' },
};

const getButtonStyle = selectedOptions => ({
  border: `1px solid ${colors.neutral.ash}`,
  borderRadius: '0.25rem',
  color:
    selectedOptions?.length > 0 ? colors.neutral.black : colors.neutral.gray,
  padding: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
});

export const ComboSelect = (props: ComboSelectProps) => {
  const {
    itemName,
    open = false,
    setOpen,
    loading,
    options,
    total,
    page,
    handlePageScroll,
    fetchOptions,
    query = '',
    onQueryChange,
    dataCyLabel,
    selectedOptions,
    onSelectOption,
    clearSelections,
    truncation = 35,
  } = props;
  const anchor = useRef(null);
  const scrollContainerRef = useRef<any>(null);

  const [pinnedOptions, setPinnedOptions] = useState<Option[]>([]);

  const handleScroll = useCallback(() => {
    if (handlePageScroll) {
      const elem = scrollContainerRef?.current;
      const isBottom =
        elem?.scrollTop + elem?.clientHeight + 0.5 >= elem?.scrollHeight;
      if (isBottom && options.length < total && !loading) {
        handlePageScroll(page + 1);
      }
    }
  }, [scrollContainerRef.current, total, page, options, handlePageScroll]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef?.current?.addEventListener('scroll', handleScroll);
      return () =>
        scrollContainerRef?.current?.removeEventListener(
          'scroll',
          handleScroll,
        );
    }
    return () => {};
  }, [handleScroll]);

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef?.current.scroll(0, 0);
    }
    onQueryChange('');
  }, [open]);

  const selectedOptionIds = useMemo(
    () => selectedOptions.map(selectedOption => selectedOption.id),
    [selectedOptions],
  );

  const handleClick = () => {
    if (isNilOrEmpty(options)) {
      fetchOptions();
    } else {
      setPinnedOptions(
        options.filter(option => selectedOptionIds.includes(option.id)),
      );
    }
    setOpen(true);
  };

  const selectedLabel: string = useMemo(() => {
    const valueString = selectedOptions
      .map(value => value.name)
      .join(', ')
      .slice(0, truncation);
    return `: ${valueString}${valueString.length >= truncation ? '...' : ''} (${
      selectedOptions.length
    })`;
  }, [selectedOptions]);

  const handleClearSelection = event => {
    event.stopPropagation();
    clearSelections();
  };

  const listDivider = <div style={styles.listDivider} />;

  const handleClickAway = () => open === true && setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          onClick={handleClick}
          ref={anchor}
          style={getButtonStyle(selectedOptions)}
          data-cy={`${dataCyLabel}_Button`}
        >
          {itemName}
          {selectedOptions?.length > 0 && selectedLabel}
          {selectedOptions?.length > 0 ? (
            <Icon onClick={handleClearSelection}>close</Icon>
          ) : (
            <Icon>arrow_drop_down</Icon>
          )}
        </div>
        <Popper
          open={open}
          keepMounted
          anchorEl={anchor.current}
          style={styles.popper}
          placement="bottom-start"
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          data-cy={`${dataCyLabel}_Dropdown`}
        >
          <div style={styles.searchInput}>
            <Icon>search</Icon>
            <TextField
              placeholder="Search"
              value={query}
              onChange={event => onQueryChange(event.target.value)}
              fullWidth={false}
              disableUnderline
            />
            {query.length > 0 && (
              <Icon style={styles.closeIcon} onClick={() => onQueryChange('')}>
                close
              </Icon>
            )}
          </div>
          <div ref={scrollContainerRef} style={styles.optionsContainer}>
            {!loading && isNilOrEmpty(options) && (
              <div style={styles.noResults}>No Results</div>
            )}
            {!isNilOrEmpty(options) && (
              <List>
                {pinnedOptions.length > 0 && listDivider}
                {pinnedOptions.map(option => (
                  <ListItemButton
                    selected={selectedOptionIds.includes(option.id)}
                    onClick={() => onSelectOption(option)}
                    key={option.id}
                    disableRipple
                    style={styles.listItem}
                  >
                    <div style={styles.pinnedItem}>
                      <div style={styles.pinnedItemName}>{option.name}</div>
                    </div>
                  </ListItemButton>
                ))}
                {pinnedOptions.length > 0 && listDivider}
                {options.map(
                  option =>
                    !pinnedOptions.includes(option) && (
                      <ListItemButton
                        selected={selectedOptionIds.includes(option.id)}
                        onClick={() => onSelectOption(option)}
                        key={option.id}
                        disableRipple
                        style={styles.listItem}
                      >
                        {option.name}
                      </ListItemButton>
                    ),
                )}
              </List>
            )}
            {loading && (
              <div>
                <Progress style={styles.progressContainer} size={20} />
              </div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ComboSelect;
