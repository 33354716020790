import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  type: string;
  handleSave: (name: string) => any;
  open: boolean;
  closeModal: () => any;
  confirmText?: string;
}

const AddItemModal = ({
  type,
  handleSave,
  open,
  closeModal,
  confirmText = 'Save',
}: Props) => {
  const [nameValue, setNameValue] = useState<string>('');

  useEffect(() => {
    setNameValue('');
  }, [open]);

  return (
    <Modal
      open={open}
      title={`Add ${textDisplayUtilities.capitalize(type)}`}
      onConfirm={() => handleSave(nameValue)}
      onCancel={closeModal}
      confirmButtonText={confirmText}
      cancelButtonText="Cancel"
      disabled={isNilOrEmpty(nameValue)}
    >
      <TextField
        id="name"
        key="name"
        label="Name"
        value={nameValue}
        onChange={event => setNameValue(event.target.value)}
      />
    </Modal>
  );
};

export default AddItemModal;
