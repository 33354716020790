import React from 'react';
import * as R from 'ramda';

import {
  SchemaTree,
  SchemaTreeAttribute,
  SelectedAttributeRoute,
} from '@atom/types/schema';

export enum SchemaDetailView {
  PREVIEW = 'PREVIEW',
  BUILDER = 'BUILDER',
}

export enum PreviewTab {
  INFO = 'INFO',
  SUB_ITEMS = 'SUB_ITEMS',
  COST = 'COST',
}

export enum DragDropType {
  ATTRIBUTE = 'attribute',
  ATTRIBUTE_GROUP = 'attributeGroup',
}

interface Context {
  schemaTree: Partial<SchemaTree>;
  dispatch: React.Dispatch<SchemaTreeActions>;
  loading: boolean;
  refetchSchemaTree: () => Promise<any>;
  view: SchemaDetailView;
  setView: (view: SchemaDetailView) => void;
  previewTab: PreviewTab;
  setPreviewTab: (previewTab: PreviewTab) => void;
  collapsedSubItems: Set<string>;
  setCollapsedSubItems: (collapsedSubItems: Set<string>) => void;
  selectedSubItem: Partial<SchemaTree>;
  selectedSubItemPath: any[];
  setSelectedSubItemPath: (selectedSubItemPath: any[]) => void;
  setSelectedAttributeRoute: (
    selectedAttributeRoute: SelectedAttributeRoute,
  ) => void;
  selectedAttributePath: any[];
  selectedAttribute: Partial<SchemaTreeAttribute>;
}

export const initialState: Context = {
  schemaTree: {},
  loading: false,
  dispatch: () => {},
  refetchSchemaTree: () => Promise.resolve({}),
  view: SchemaDetailView.PREVIEW,
  setView: () => {},
  previewTab: PreviewTab.INFO,
  setPreviewTab: () => {},
  collapsedSubItems: new Set([]),
  setCollapsedSubItems: () => {},
  selectedSubItem: {},
  selectedSubItemPath: null,
  setSelectedSubItemPath: () => {},
  setSelectedAttributeRoute: () => {},
  selectedAttributePath: null,
  selectedAttribute: {},
};

const SchemaDetailContext = React.createContext<Context>(initialState);

export enum SchemaTreeActionTypes {
  SET_SCHEMA_TREE = 'SET_SCHEMA_TREE',
  UPDATE_SCHEMA_PROPERTY = 'UPDATE_SCHEMA_PROPERTY',
}

interface SetSchemaTree {
  type: SchemaTreeActionTypes.SET_SCHEMA_TREE;
  data: SchemaTree;
}

interface UpdateSchemaProperty {
  type: SchemaTreeActionTypes.UPDATE_SCHEMA_PROPERTY;
  data: {
    path: any[];
    property: keyof SchemaTree;
    value: any;
  };
}

type SchemaTreeActions = SetSchemaTree | UpdateSchemaProperty;

const updateSchemaProperty = (
  state: SchemaTree,
  action: UpdateSchemaProperty,
) => {
  const { path, property, value } = action.data;
  return R.set(R.lensPath([...path, property]), value)(state);
};

export const reducer = (
  state: SchemaTree,
  action: SchemaTreeActions,
): SchemaTree => {
  switch (action.type) {
    case SchemaTreeActionTypes.SET_SCHEMA_TREE:
      return action.data;
    case SchemaTreeActionTypes.UPDATE_SCHEMA_PROPERTY:
      return updateSchemaProperty(state, action);
    default: {
      return state;
    }
  }
};

export default SchemaDetailContext;
