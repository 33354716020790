import React, { useEffect, useState } from 'react';

import { Modal, TextField } from '@atom/mui';
import textDisplayUtilities from '@atom/utilities/textDisplayUtilities';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

interface Props {
  name: string;
  type: string;
  handleSave: (name: string) => any;
  open: boolean;
  closeModal: () => any;
  confirmText?: string;
}

const EditItemModal = ({
  name,
  type,
  handleSave,
  open,
  closeModal,
  confirmText = 'Save',
}: Props) => {
  const [nameValue, setNameValue] = useState<string>(name);

  useEffect(() => {
    setNameValue(name);
  }, [name, open]);

  const error = isNilOrEmpty(nameValue);
  const helperText = error ? `The ${type} name cannot be blank` : '';

  return (
    <Modal
      open={open}
      title={`Rename ${textDisplayUtilities.capitalize(type)}`}
      onConfirm={() => handleSave(nameValue)}
      onCancel={closeModal}
      confirmButtonText={confirmText}
      cancelButtonText="Cancel"
      disabled={error}
    >
      <TextField
        id="name"
        key="name"
        label="Name"
        value={nameValue}
        onChange={event => setNameValue(event.target.value)}
        helperText={helperText}
        error={error}
      />
    </Modal>
  );
};

export default EditItemModal;
