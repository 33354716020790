import React, { useMemo, useReducer, useState } from 'react';
import { match as Match } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import SchemaDetailContext, {
  PreviewTab,
  reducer,
  SchemaDetailView,
  SchemaTreeActionTypes,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { GET_SCHEMA_TREE } from '@atom/graph/schema';
import { Progress } from '@atom/mui';
import { SchemaTree, SelectedAttributeRoute } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SchemaBuilder from './schemaBuilder/SchemaBuilder';
import SchemaDetailHeader from './schemaDetailHeader/SchemaDetailHeader';
import SchemaPreview from './schemaPreview/SchemaPreview';
import { getAttributePathFromRoute } from './schemaDetailUtilities';

import './schemaDetail.css';

interface Props {
  match: Match<{ id: string }>;
}

const SchemaDetail = ({ match }: Props) => {
  const [schemaTree, dispatch] = useReducer(reducer, {} as SchemaTree);

  const [view, setView] = useState<SchemaDetailView>(SchemaDetailView.PREVIEW);
  const [previewTab, setPreviewTab] = useState<PreviewTab>(PreviewTab.INFO);
  const [collapsedSubItems, setCollapsedSubItems] = useState<Set<string>>(
    new Set([]),
  );
  const [selectedSubItemPath, setSelectedSubItemPath] = useState<any[]>(null);
  const [selectedAttributeRoute, setSelectedAttributeRoute] = useState<
    SelectedAttributeRoute
  >(null);
  const [selectedAttributePath, setSelectedAttributePath] = useState<any[]>(
    null,
  );

  const selectedSubItem = useMemo(() => {
    if (R.isNil(selectedSubItemPath)) {
      return {};
    }

    return R.view(R.lensPath(selectedSubItemPath), schemaTree) || {};
  }, [schemaTree, selectedSubItemPath]);

  const selectedAttribute = useMemo(() => {
    if (R.isNil(selectedAttributeRoute)) {
      setSelectedAttributePath(null);
      return {};
    }

    if (R.isNil(selectedSubItem)) {
      setSelectedAttributePath(null);
      setSelectedAttributeRoute(null);
      return {};
    }

    const path = getAttributePathFromRoute(
      selectedSubItem,
      selectedAttributeRoute,
    );

    setSelectedAttributePath(path);
    return R.view(R.lensPath(path), selectedSubItem) || {};
  }, [schemaTree, selectedSubItem, selectedAttributeRoute]);

  const { refetch: refetchSchemaTree, loading: schemaTreeLoading } = useQuery(
    GET_SCHEMA_TREE,
    {
      variables: {
        id: match.params.id,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        dispatch({
          type: SchemaTreeActionTypes.SET_SCHEMA_TREE,
          data: data?.schemaTree,
        });
      },
    },
  );

  const schemaDetailLoading = isNilOrEmpty(schemaTree);

  const getActiveView = () => {
    const views = {
      [SchemaDetailView.PREVIEW]: <SchemaPreview />,
      [SchemaDetailView.BUILDER]: <SchemaBuilder />,
    };

    return views[view];
  };

  const viewStyle = view === SchemaDetailView.PREVIEW ? 'view preview' : 'view';
  const loading = schemaTreeLoading;

  return (
    <SchemaDetailContext.Provider
      value={{
        schemaTree,
        dispatch,
        loading,
        refetchSchemaTree,
        view,
        setView,
        previewTab,
        setPreviewTab,
        collapsedSubItems,
        setCollapsedSubItems,
        selectedSubItem,
        selectedSubItemPath,
        setSelectedSubItemPath,
        setSelectedAttributeRoute,
        selectedAttributePath,
        selectedAttribute,
      }}
    >
      {schemaDetailLoading ? (
        <Progress />
      ) : (
        <>
          <SchemaDetailHeader />
          <div styleName={viewStyle}>{getActiveView()}</div>
        </>
      )}
    </SchemaDetailContext.Provider>
  );
};

export default SchemaDetail;
