import React, { useContext } from 'react';

import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import SubItemDetail from './subItemDetail/SubItemDetail';
import SubItemTree from './subItemTree/SubItemTree';

import './schemaBuilder.css';

const SchemaBuilder = () => {
  const { selectedSubItem, selectedAttribute } = useContext(
    SchemaDetailContext,
  );

  const showSubItemDetail = !isNilOrEmpty(selectedSubItem);
  const showAttributeDetail =
    !isNilOrEmpty(selectedSubItem) && !isNilOrEmpty(selectedAttribute);

  return (
    <>
      <div styleName="builder-column">
        <SubItemTree />
      </div>
      {showSubItemDetail && (
        <div styleName="builder-column">
          <SubItemDetail />
        </div>
      )}
      {showAttributeDetail && (
        <div styleName="builder-column">Attribute Detail</div>
      )}
    </>
  );
};

export default SchemaBuilder;
