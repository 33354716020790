import { DragDrop } from 'react-beautiful-dnd';
import { SchemaTree, SelectedAttributeRoute } from 'client/types/schema';
import * as R from 'ramda';

export const getAttributePathFromRoute = (
  subItem: Partial<SchemaTree>,
  route: SelectedAttributeRoute,
): any[] => {
  const groupIndex = R.findIndex(R.propEq('id', route.attributeGroupId))(
    subItem?.attributeGroups || [],
  );

  const group = subItem.attributeGroups[groupIndex];

  const attributeIndex = R.findIndex(R.propEq('id', route.attributeId))(
    group?.attributes || [],
  );

  return ['attributeGroups', groupIndex, 'attributes', attributeIndex];
};

export const getAttributeGroupsPayload = attributeGroups => {
  return attributeGroups.map(attributeGroup => ({
    ...attributeGroup,
    attributes: attributeGroup.attributes.map(attribute => attribute.id),
  }));
};

export const reorderAttributeGroups = (
  schema: Partial<SchemaTree>,
  result: DragDrop,
) => {
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  const updatedAttributeGroups = R.move(
    sourceIndex,
    destinationIndex,
    schema.attributeGroups,
  );

  return {
    updatedAttributeGroups,
    payload: {
      schemaId: schema.id,
      attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
    },
  };
};

export const reorderAttributes = (
  schema: Partial<SchemaTree>,
  result: DragDrop,
) => {
  const attributeId = result.draggableId;
  const sourceGroupId = result.source.droppableId;
  const sourceIndex = result.source.index;
  const destinationGroupId = result.destination.droppableId;
  const destinationIndex = result.destination.index;

  const originalAttributeGroup = schema.attributeGroups.find(
    group => group.id === sourceGroupId,
  );

  // Handles moving attributes within same attributeGroup
  if (sourceGroupId === destinationGroupId) {
    const updatedAttributeGroups = schema.attributeGroups.map(group => {
      return group.id === sourceGroupId
        ? {
            ...group,
            attributes: R.move(
              sourceIndex,
              destinationIndex,
              originalAttributeGroup.attributes,
            ),
          }
        : group;
    });

    return {
      updatedAttributeGroups,
      payload: {
        schemaId: schema.id,
        attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
      },
    };
  }

  // Handles moving attributes across attributeGroups
  const newAttributeGroup = schema.attributeGroups.find(
    group => group.id === destinationGroupId,
  );

  const selectedAttribute = originalAttributeGroup.attributes.find(
    attribute => attribute.id === attributeId,
  );

  const updatedAttributeGroups = schema.attributeGroups.map(group => {
    switch (group.id) {
      // Remove attribute from original attributeGroup
      case sourceGroupId:
        return {
          ...group,
          attributes: R.remove(
            sourceIndex,
            1,
            originalAttributeGroup.attributes,
          ),
        };
      // Add attribute to destination attributeGroup at specified location
      case destinationGroupId:
        return {
          ...group,
          attributes: R.insert(
            destinationIndex,
            selectedAttribute,
            newAttributeGroup.attributes,
          ),
        };
      default:
        return group;
    }
  });

  return {
    updatedAttributeGroups,
    payload: {
      schemaId: schema.id,
      attributeGroups: getAttributeGroupsPayload(updatedAttributeGroups),
    },
  };
};
