import React, { useContext } from 'react';
import { DragDrop } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from '@atom/components/common/dragAndDrop';
import SchemaDetailContext, {
  DragDropType,
  SchemaTreeActionTypes,
} from '@atom/components/schemaDetail/SchemaDetailContext';
import { ELEMENT_UPDATE } from '@atom/graph/schema';
import {
  ElementUpdateInput,
  SchemaTree,
  SchemaTreeAttributeGroup,
} from '@atom/types/schema';

import {
  reorderAttributeGroups,
  reorderAttributes,
} from '../../schemaDetailUtilities';

import AttributeGroupRow from './AttributeGroupRow';
import DetailHeader from './DetailHeader';

import './subItemDetail.css';

const SubItemDetail = () => {
  const {
    schemaTree,
    selectedSubItem,
    selectedSubItemPath,
    dispatch,
    refetchSchemaTree,
  } = useContext(SchemaDetailContext);

  const [updateElement] = useMutation<
    { elementUpdate: SchemaTree },
    { input: ElementUpdateInput }
  >(ELEMENT_UPDATE);

  const onDragEnd = async (result: DragDrop) => {
    const { destination, type } = result;

    if (!destination) {
      return;
    }

    const { updatedAttributeGroups, payload } =
      type === DragDropType.ATTRIBUTE_GROUP
        ? reorderAttributeGroups(selectedSubItem, result)
        : reorderAttributes(selectedSubItem, result);

    dispatch({
      type: SchemaTreeActionTypes.UPDATE_SCHEMA_PROPERTY,
      data: {
        path: selectedSubItemPath,
        property: 'attributeGroups',
        value: updatedAttributeGroups,
      },
    });

    await updateElement({
      variables: {
        input: payload,
      },
    });

    refetchSchemaTree();
  };

  const isDisabled = schemaTree?.isPublished;

  return (
    <>
      <DetailHeader />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="subItemDetail"
          type={DragDropType.ATTRIBUTE_GROUP}
          isDropDisabled={isDisabled}
        >
          {selectedSubItem.attributeGroups.map(
            (attributeGroup: SchemaTreeAttributeGroup, index: number) => {
              return (
                <Draggable
                  key={attributeGroup.id}
                  draggableId={attributeGroup.id}
                  index={index}
                  type={DragDropType.ATTRIBUTE_GROUP}
                  isDragDisabled={isDisabled}
                >
                  <AttributeGroupRow
                    key={attributeGroup.id}
                    attributeGroup={attributeGroup}
                  />
                </Draggable>
              );
            },
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default SubItemDetail;
