import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import DeleteModal from '@atom/components/common/DeleteModal';
import SchemaDetailContext from '@atom/components/schemaDetail/SchemaDetailContext';
import { ATTRIBUTE_DELETE } from '@atom/graph/schema';
import { Icon, IconButton, List } from '@atom/mui';
import { getAttributeIconName } from '@atom/selectors/schemaSelectors';
import colors from '@atom/styles/colors';
import fonts from '@atom/styles/fonts';
import { AttributeDeleteInput, SchemaTreeAttribute } from '@atom/types/schema';

import ListItemWrapper from '../subItemTree/ListItemWrapper';

import './subItemDetail.css';

const { ListItemText } = List;

const styles = {
  primaryText: {
    fontWeight: '400',
    fontSize: fonts.md,
    paddingLeft: '0.5rem',
  },
  dragIcon: {
    color: colors.neutral.silver,
    paddingRight: '0.25rem',
  },
};

interface Props {
  attribute: SchemaTreeAttribute;
  attributeGroupId: string;
}

const AttributeRow = ({ attribute, attributeGroupId }: Props) => {
  const {
    selectedAttribute,
    setSelectedAttributeRoute,
    schemaTree,
    refetchSchemaTree,
    selectedSubItem,
  } = useContext(SchemaDetailContext);

  const [hovering, setHovering] = useState<boolean>(false);
  const [openAttributeDelete, setOpenAttributeDelete] = useState<boolean>(
    false,
  );

  const [deleteAttribute] = useMutation<
    { attributeDelete: boolean },
    { input: AttributeDeleteInput }
  >(ATTRIBUTE_DELETE);

  const handleAttributeClick = () => {
    setSelectedAttributeRoute({
      attributeGroupId,
      attributeId: attribute.id,
    });
  };

  const handleAttributeDelete = async () => {
    await deleteAttribute({
      variables: {
        input: {
          schemaId: selectedSubItem?.id,
          attributeGroupId,
          attributeId: attribute?.id,
        },
      },
    });

    setSelectedAttributeRoute(null);
    await refetchSchemaTree();
  };

  const selected = selectedAttribute?.id === attribute?.id;

  const attributeIcon = getAttributeIconName(attribute.dataType);
  const textStyles = {
    ...styles.primaryText,
    color: selected ? colors.brand.blue : colors.neutral.dark,
  };
  const iconStyles = {
    color: selected ? colors.brand.blue : colors.neutral.gray,
  };

  const showActionButtons = (hovering || selected) && !schemaTree.isPublished;

  return (
    <>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <ListItemWrapper
          onClick={handleAttributeClick}
          selected={selected}
          attributeVersion
        >
          <>
            <Icon style={styles.dragIcon}>drag_indicator_icon</Icon>
            <Icon style={iconStyles}>{attributeIcon}</Icon>
            <ListItemText
              primary={attribute?.name}
              primaryTextStyle={textStyles}
            />
            {showActionButtons && (
              <div>
                <IconButton
                  tooltip="Delete"
                  onClick={() => setOpenAttributeDelete(true)}
                  size="small"
                >
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            )}
          </>
        </ListItemWrapper>
      </div>
      <DeleteModal
        open={openAttributeDelete}
        onConfirm={handleAttributeDelete}
        onCancel={() => setOpenAttributeDelete(false)}
        title="Delete Attribute?"
        content={`Are you  sure you want to delete the ${attribute.name} attribute?`}
        cancelText="Cancel"
        confirmText="Delete"
      />
    </>
  );
};

export default AttributeRow;
