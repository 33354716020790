import { gql } from '@apollo/client';

export const BASE_SCHEMA_FRAGMENT = gql`
  fragment BaseSchema on Schema {
    id
    name
    assetType
    attributes
    attributeGroups {
      id
      name
      attributes
    }
    elements {
      id
      name
      group
      markerId
    }
    assetCount
    elementCount
    colorId
    markerId
    isMaterial
    locationType
  }
`;

export const BASE_SCHEMA_TREE_FRAGMENT = gql`
  fragment BaseSchemaTree on SchemaTree {
    id
    name
    isPublished
    isMaterial
    locationType
    markerId
    colorId
    group
    assetType
    attributeGroups {
      id
      name
      attributes {
        id
        name
        dataType
        defaultValue
        enumeration
        unit
        isVisibleAsSubtext
        isFilterable
        isEditable
      }
    }
    elements
    elementGroups {
      id
      name
      elements
    }
    budget {
      isFixed
      isTimeBased
      type
      stockBasedOption
      startEndReadingOption
      rate
      unit
    }
    createdBy
    createdDate
    updatedBy
    updatedDate
  }
`;

export const GET_SCHEMA = gql`
  query schema($id: ID!) {
    schema(id: $id) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const GET_SCHEMAS = gql`
  query schemas($input: SchemasInput!) {
    schemas(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const GET_SCHEMA_TREE = gql`
  query schemaTree($id: ID!) {
    schemaTree(id: $id) {
      ...BaseSchemaTree
    }
  }
  ${BASE_SCHEMA_TREE_FRAGMENT}
`;

export const SCHEMA_CREATE = gql`
  mutation schemaCreate($input: SchemaCreateInput!) {
    schemaCreate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const SCHEMA_DUPLICATE = gql`
  mutation schemaDuplicate($id: ID!) {
    schemaDuplicate(id: $id) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const SCHEMA_UPDATE = gql`
  mutation schemaUpdate($input: SchemaUpdateInput!) {
    schemaUpdate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const SCHEMA_DELETE = gql`
  mutation schemaDelete($id: ID!) {
    schemaDelete(id: $id)
  }
`;

export const ELEMENT_CREATE = gql`
  mutation elementCreate($input: ElementCreateInput!) {
    elementCreate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ELEMENT_UPDATE = gql`
  mutation elementUpdate($input: ElementUpdateInput!) {
    elementUpdate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ELEMENT_DELETE = gql`
  mutation elementDelete($id: ID!) {
    elementDelete(id: $id)
  }
`;

export const ELEMENT_GROUP_CREATE = gql`
  mutation elementGroupCreate($input: ElementGroupCreateInput!) {
    elementGroupCreate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ELEMENT_GROUP_UPDATE = gql`
  mutation elementGroupUpdate($input: ElementGroupUpdateInput!) {
    elementGroupUpdate(input: $input)
  }
`;

export const ELEMENT_GROUP_DELETE = gql`
  mutation elementGroupDelete($input: ElementGroupDeleteInput!) {
    elementGroupDelete(input: $input)
  }
`;

export const ATTRIBUTE_GROUP_CREATE = gql`
  mutation attributeGroupCreate($input: AttributeGroupCreateInput!) {
    attributeGroupCreate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ATTRIBUTE_GROUP_UPDATE = gql`
  mutation attributeGroupUpdate($input: AttributeGroupUpdateInput!) {
    attributeGroupUpdate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ATTRIBUTE_GROUP_DELETE = gql`
  mutation attributeGroupDelete($input: AttributeGroupDeleteInput!) {
    attributeGroupDelete(input: $input)
  }
`;

export const ATTRIBUTE_CREATE = gql`
  mutation attributeCreate($input: AttributeCreateInput!) {
    attributeCreate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ATTRIBUTE_UPDATE = gql`
  mutation attributeUpdate($input: AttributeUpdateInput!) {
    attributeUpdate(input: $input) {
      ...BaseSchema
    }
  }
  ${BASE_SCHEMA_FRAGMENT}
`;

export const ATTRIBUTE_DELETE = gql`
  mutation attributeDelete($input: AttributeDeleteInput!) {
    attributeDelete(input: $input)
  }
`;
